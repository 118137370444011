import React, { ComponentProps, forwardRef } from "react"
import styled from "styled-components"
import ArrowRight from "../../images/arrow-right.svg"
import ArrowLeft from "../../images/arrow-left.svg"
import { COLORS, FONTS } from "../../styles"
import { AutoLink } from "../link"

const BaseCTA = styled(AutoLink).withConfig({
  shouldForwardProp: (prop, defaultValidator) =>
    (prop !== "color" && defaultValidator(prop)) || prop === "state",
})<{ color?: string }>`
  width: fit-content;
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 400;
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: 0.39px;
  display: flex;
  align-items: flex-end;
  color: ${props => {
    switch (props.color) {
      case "white":
        return COLORS.WHITE
      case "green":
        return COLORS.BOTTLE_GREEN
      default:
        return props.color || COLORS.BOTTLE_GREEN
    }
  }};
  text-decoration: none;
`

const RightArrow = styled(ArrowRight)`
  position: relative;
  top: -3px;
  margin-left: 7px;
  height: 8px;
  fill: currentColor;
  ${BaseCTA}:hover & {
    margin-left: 10px;
  }
  :hover {
    margin-left: 10px;
  }
`
const LeftArrow = styled(ArrowLeft)`
  position: relative;
  top: -3px;
  margin-right: 7px;
  height: 8px;
  fill: currentColor;
  ${BaseCTA}:hover & {
    margin-right: 10px;
  }
  :hover {
    margin-right: 10px;
  }
`

// eslint-disable-next-line react/display-name
export const CTA = forwardRef<HTMLElement, ComponentProps<typeof BaseCTA>>(
  ({ children, reverse, ...props }, ref) => (
    <BaseCTA {...props} ref={ref}>
      {reverse && <LeftArrow />}
      {children}
      {!reverse && <RightArrow />}
    </BaseCTA>
  )
)
